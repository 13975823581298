@import "variables";
@import "skinVariables";
@import "~base/skin/skin";
@import "bootstrap/scss/mixins/breakpoints";

body,
.card,
.hero {
	background-color: var(--skin-background-color-1);
	font-family: var(--skin-body-font);
	color: var(--skin-main-text-color-1);
}

.card-header {
	background-color: var(--skin-primary-color-1);
	font-family: var(--skin-body-font);
	color: var(--skin-menu-color-1-invert);
}

a,
.textAsLink {
	color: var(--skin-link-color-1);
	border-bottom: 1px solid var(--skin-primary-color-1);

	&:hover {
		border-color: var(--skin-secondary-color-1);
	}
}

.price {
	color: var(--skin-price-1);
}

.dropdown-menu a {
	color: var(--skin-menu-color-1);

	@include media-breakpoint-up('lg') {
		color: var(--skin-menu-color-1-invert);
	}
}

.card-header > a,
.card-header > span {
	color: var(--skin-menu-color-1-invert) !important;

	&:hover {
		border-color: var(--skin-menu-color-1-invert);
	}
}

.hero {
	border-bottom-color: var(--skin-background-color-1);
}

.banner-color {
	background-color: var(--skin-banner-background-color-1);
}

.header-banner {
	background-color: var(--skin-banner-background-color-2);
	color: var(--skin-banner-text-color-1);
}

.header-banner .close-button .close {
	background-color: var(--skin-primary-color-1);
}

h1.header.page-title,
h1.header.page-title::before {
	background-color: var(--skin-heading-color-1);
	color: var(--skin-heading-color-1-invert);
}

.product-tile {
	background-color: var(--skin-background-color-1);
	border: 10px solid var(--skin-border-color-1);
}

.refinements ul li button {
	color: var(--skin-primary-color-1);
}

.custom-select {
	background-color: var(--skin-selectbox-background-color-1);
	color: var(--skin-selectbox-text-color-1);
}

.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu {
	color: var(--skin-menu-color-1);
}

.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown .dropdown-menu.show {
	color: var(--skin-selectbox-text-color-1);
}

.navbar .nav-item.dropdown .dropdown-menu.show {
	background-color: var(--skin-selectbox-background-color-1);

	a,
	.nav-item {
		color: var(--skin-selectbox-text-color-1);
	}
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
	background-color: var(--skin-menu-color-1-invert);
	color: var(--skin-menu-color-1) !important;
}

.btn-primary {
	background-color: #{var(--skin-btn-primary-bg)};
	border-color: #{var(--skin-btn-primary-bg)};
	color: var(--skin-btn-primary-color);
}

.add-to-cart {
	background-color: #{var(--skin-secondary-color-1)} !important;
	border-color: #{var(--skin-secondary-color-1)} !important;
}

.btn-primary:hover {
	color: #{var(--skin-btn-primary-color)};
	border-color: #{var(--skin-btn-primary-bg-hover)};
	background-color: var(--skin-btn-primary-bg-hover);
}

.btn-primary.disabled,
.btn-primary:disabled {
	background-color: var(--skin-btn-primary-bg-disabled);
	border-color: var(--skin-btn-primary-bg-disabled);
	color: var(--skin-btn-primary-color-disabled);
}

.btn-outline-primary {
	color: var(--skin-primary-color-1);
	border-color: var(--skin-outlineButton-primary);
}

.btn-outline-primary:hover {
	color: var(--skin-primary-color-1);
	background-color: var(--white);
	border-color: var(--skin-primary-color-1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--skin-header-font), sans-serif;
}
