@import "../variables";

:root {
	/** ==================================================================== **/

	/** Create Custom CSS Variables from "sfra bootstrap override" variables **/

	/** ==================================================================== **/
	--color-white: #{$white};
	--color-black: #{$black};
	--color-blue: #{$blue};
	--color-green: #{$green};
	--color-primary: #{$primary};
	--color-secondary: #{$secondary};
	--color-red: var(--color-secondary);
	--color-success: var(--color-green);
	--color-danger: var(--color-red);
	--color-light-blue: #{$light-blue};

	// Consolidated values
	--color-grey1: #{$grey1};
	--color-grey2: #{$grey2};
	--color-grey25: #{$grey25};
	--color-grey3: #{$grey3};
	--color-grey4: #{$grey4};
	--color-grey5: #{$grey5};
	--color-grey52: #{$grey52};
	--color-grey55: #{$grey55};
	--color-grey6: #{$grey6};
	--color-grey65: #{$grey65};
	--color-grey7: #{$grey7};
	--color-grey8: #{$grey8};
	--color-grey-transparent-1: rgba(0, 0, 0, 0.65);
	--color-grey-transparent-2: rgba(0, 0, 0, 0.25);
	--color-light-gray: var(--color-grey1);
	--color-slightly-darker-gray: var(--color-grey4);
	--color-dark-gray: var(--color-grey6);
	--color-darker-gray: var(--color-grey7);
	--color-horizontal-rule-grey: var(--color-grey3);
	--color-product-number-grey: var(--color-grey3);
	--color-horizontal-border-grey: var(--color-grey4);
	--color-menu-link: var(--color-grey6);
	--color-close-menu-bg: var(--color-grey2);
	--color-link-color: var(--color-dark-gray);
	--color-hr-border-color: var(--color-grey3);

	/** ==================================================================== **/

	/** Define skin named variables here                                    **/

	/** ==================================================================== **/
	--skin-background-color-1: var(--color-white);
	--skin-border-color-1: var(--color-white);
	--skin-main-text-color-1: var(--color-primary);
	--skin-main-text-color-2: var(--color-primary);
	--skin-menu-color-1: #444;
	--skin-menu-color-1-invert: var(--color-white);
	--skin-menu-color-2: #222;
	--skin-link-color-1: var(--color-primary);
	--skin-link-color-2: var(--color-primary);
	--skin-primary-color-1: var(--color-primary);
	--skin-primary-color-2: var(--color-primary);
	--skin-primary-color-invert-1: var(--color-grey52);
	--skin-secondary-color-1: var(--color-secondary);
	--skin-btn-primary-bg: var(--skin-primary-color-1);
	--skin-btn-primary-color: var(--color-white);
	--skin-btn-primary-bg-hover: var(--color-grey52);
	--skin-btn-primary-color-disabled: var(--color-grey3);
	--skin-btn-primary-bg-disabled: var(--color-grey2);
	--skin-selectbox-background-color-1: var(--color-white);
	--skin-selectbox-text-color-1: var(--skin-main-text-color-1);
	--skin-banner-background-color-1: var(--color-primary);
	--skin-banner-background-color-2: #222;
	--skin-banner-text-color-1: var(--color-white);
	--skin-heading-color-1: var(--color-primary);
	--skin-heading-color-1-invert: var(--color-white);
	--skin-price-1: var(--color-grey65);
	--skin-header-font: 'Roboto Condensed';
	--skin-body-font: var(--font-family-sans-serif);
}
